import React, { useState } from "react";
import { Row } from "react-aria-components";
import TableHeader, { TableHeadProps, TableHeadType } from "../../../../components/common/Table/TableHeader";
import { SortOrder } from "../../../../types";

interface SolventMixType {
  S1: string;
  S1P: number;
  S2: string;
  S2P: number;
  S3: string;
  S3P: number;
  imported?: boolean;
  solubility?: string;
}

interface TableProps {
  data: SolventMixType[];
  manageSort: (newSortBy:string) => void;
  sortBy: string;
  sortOrder : string
}

const defaultTableData: SolventMixType[] = [
  {
    S1: "x",
    S1P: 1,
    S2: "y",
    S2P: 2,
    S3: "z",
    S3P: 3,
  },
  {
    S1: "x",
    S1P: 1,
    S2: "y",
    S2P: 2,
    S3: "z",
    S3P: 3,
  },
  {
    S1: "x",
    S1P: 1,
    S2: "y",
    S2P: 2,
    S3: "z",
    S3P: 3,
  },
  {
    S1: "x",
    S1P: 1,
    S2: "y",
    S2P: 2,
    S3: "z",
    S3P: 3,
  },
];



const Table = ({ data , manageSort , sortBy , sortOrder}: TableProps) => {
      
      const tableHeaders:TableHeadProps[] = [
        {
            title : "ID",
            // alignCenter: true,
        },
        {
            title : "Solvent 1",
            // alignCenter: true,
            fieldName: "S1",
        },
        {
            title : "Composition 1",
            // alignCenter: true,
            fieldName: "S1P",
        },
        {
            title : "Solvent 2",
            // alignCenter: true,
            fieldName: "S2",
        },
        {
            title : "Composition 2",
            // alignCenter: true,
            fieldName: "S2P",
        },
        {
            title : "Solvent 3",
            // alignCenter: true,
            fieldName: "S3",
        },
        {
            title : "Composition 3",
            // alignCenter: true,
            fieldName: "S3P",
        },
        {
            title : "Solubility (output)",
            fieldName: "solubility",
            type: TableHeadType.SORTABLE,
            alignCenter: true,
            sortBy,
            sortOrder,
            manageSort
        },
    ]

  return (
    <div className="table-outer">
                    <div className="table-responsive theme-table">
    <table
      className="table"
      title="Solvents"
    >
        <TableHeader heads={tableHeaders} />
      <tbody>
        {data?.length > 0
          ? data?.map((row, index) => {
              return (
                <tr
                  className="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
                  key={index}
                >
                  <th className="px-2 py-3">
                    {index + 1}
                  </th>
                  <th className="px-2 py-3">
                    {row?.S1}
                  </th>
                  <td className="px-2 py-3">{row?.S1P || "-"}</td>
                  <td className="px-2 py-3">{row?.S2}</td>
                  <td className="px-2 py-3">{row?.S2P || "-"}</td>
                  <td className="px-2 py-3">{row?.S3}</td>
                  <td className="px-2 py-3">{row?.S3P || "-"}</td>
                  <td className="px-2 py-3">{row?.solubility || "-"}</td>
                </tr>
              );
            })
          : defaultTableData?.map((dRow, id) => {
              return (
                <tr
                  className="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
                  key={id}
                >
                  <th className="px-2 py-3">
                    {id + 1}
                  </th>
                  <th className="px-2 py-3">
                    {dRow?.S1}
                  </th>
                  <td className="px-2 py-3">{dRow?.S1P}</td>
                  <td className="px-2 py-3">{dRow?.S2}</td>
                  <td className="px-2 py-3">{dRow?.S2P}</td>
                  <td className="px-2 py-3">{dRow?.S3}</td>
                  <td className="px-2 py-3">{dRow?.S3P}</td>
                </tr>
              );
            })}
      </tbody>
    </table>
    </div>
    </div>
  );
};

export default Table;
