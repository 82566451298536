import React, { useEffect, useState } from "react";
import Table from "./Table";
import ContourGraph from "../ContourGraph";
import SolventListModal from "./SolventListModal";
import { VendorProps } from "../../../../types/materialsTypes";
import { vendorNames } from "../../../../utils/materialHelper";
import {
  sortByField,
  sortItemsByKey,
  uniqueItemsByKey,
} from "../../../../utils/common";
import { useOktaAuth } from "@okta/okta-react";
import MaterialService from "../../../../services/materialService";
import { apiRoutes } from "../../../../utils/apiRoutes";
import { alertCloseAction, alertOpenAction } from "../../../../redux/actions";
import { useDispatch } from "react-redux";
import CustomLoader from "../../../../components/common/CustomLoader";
import { toast } from "react-toastify"; // Ensure this is imported
import "react-toastify/dist/ReactToastify.css"; // Toastify styles
import * as XLSX from "xlsx";
import { SortOrder } from "../../../../types";
import { saveAs } from "file-saver";

interface SolventMixType {
  S1: string;
  S1P: number;
  S2: string;
  S2P: number;
  S3: string;
  S3P: number;
  imported?: boolean;
  solubility?: any;
  // solubility?: ""
}

const CosmoRevised = () => {
  const [tableData, setTableData] = useState<SolventMixType[]>([]);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [vendors, setVendors] = useState<VendorProps[]>(vendorNames);
  const [lotNumberOptions, setLotNumberOptions] = useState<any[]>([]);
  const [materialNameOptions, setMaterialNameOptions] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useDispatch();
  const [vendorName, setVendorName] = useState<string>("");
  const [material, setMaterial] = useState<string>("");
  const [lotNumber, setLotNumber] = useState<string>("");
  const [melting_point, setMeltingPoint] = useState<string>("");
  const [heat_of_fusion, setHfusion] = useState<string>("");
  const [mpEnabled, setMpEnabled] = useState(false);
  const [hfEnabled, setHfEnabled] = useState(false);
  const [isChecking, setIsChecking] = useState<boolean>(false);
  const [isCheckingSolubility, setIsCheckingSolubility] =
    useState<boolean>(false);
  const [isDownloadEnabled, setIsDownloadEnabled] = useState<boolean>(false);
  const [logStatus, setLogStatus] = useState<string>("");
  const [logStatusSolubility, setLogStatusSolubility] = useState<string>("");
  const [runOutput, setRunOutput] = useState<string>("");
  const [casNumber, setCasNumber] = useState<string>("");
  const [smiles, setSmiles] = useState<string>("");
  const [runEnabled, setRunEnabled] = useState<boolean>(false);
  const [checkStatusEnabled, setCheckStatusEnabled] = useState<boolean>(false);
  const [deleteBeforeRun, setDeleteBeforeRun] = useState<boolean>(false);
  const [sortBy, setSortBy] = useState<string>("y");
  const [sortOrder, setSortOrder] = useState<SortOrder>(SortOrder.DESC);
  const [selectedOption, setSelectedOption] = useState<string>("");

  // auth
  const { authState } = useOktaAuth();
  const auth: any = authState ? authState?.accessToken : "";

  // method to return material list based on selected vendor_name field.
  const getMaterialNames = async (vendor_name: string) => {
    const payload = {
      uid: `${auth?.claims?.uid}`,
      vendor_name,
    };
    setLoading(true); // enable loading
    const res = await MaterialService.create(
      apiRoutes.LIST_MATERIAL_NAMES_MATERIAL,
      payload
    );
    if (res?.data?.code == 200) {
      if (res?.data?.body?.length) {
        const materials = sortItemsByKey(
          uniqueItemsByKey(res?.data?.body, "material_name"),
          "material_name"
        );
        setMaterialNameOptions(materials);
      } else {
        setMaterialNameOptions([]);
        setMaterial("");
        setLotNumber("");
        setLotNumberOptions([]);
      }
    } else {
      setMaterialNameOptions([]);
      setMaterial("");
      setLotNumber("");
      setLotNumberOptions([]);
      dispatch(alertOpenAction("No related materials present.", "error"));
      setTimeout(() => dispatch(alertCloseAction()));
    }
    setLoading(false);
  };

  // method to return lot number based on selected vendor_name and material_name fields.
  const getLotNumbers = async () => {
    const payload = {
      uid: `${auth?.claims?.uid}`,
      material_name: material,
      vendor_name: vendorName,
    };
    setLoading(true);
    const res = await MaterialService.create(
      apiRoutes.LIST_LOT_NUMBERS_MATERIAL,
      payload
    );
    if (res?.data?.code === 200) {
      if (res?.data?.body?.length) {
        const lotnumbers = sortItemsByKey(
          uniqueItemsByKey(res?.data?.body, "lot_number"),
          "lot_number"
        );
        setLotNumberOptions(lotnumbers);
        setLotNumber(lotnumbers[0].lot_number);
      } else {
        setLotNumberOptions([]);
        setLotNumber("");
      }
    } else {
      setLotNumberOptions([]);
      setLotNumber("");
      dispatch(
        alertOpenAction("Unable to load the related Lot Numbers.", "error")
      );
      setTimeout(() => dispatch(alertCloseAction()));
    }
    setLoading(false);
  };
  const getMaterial = async (
    vendor_name: string,
    material_name: string,
    lot_number: string,
    melting_point: string,
    heat_of_fusion: string
  ) => {
    const payload = {
      uid: `${auth?.claims?.uid}`,
      vendor_name,
      material_name,
      lot_number,
      melting_point,
      heat_of_fusion,
    };
    setLoading(true);
    const res = await MaterialService.create(apiRoutes.FIND_MATERIAL, payload);

    setLoading(false);
    if (res?.data?.code == 200) {
      setCasNumber(res?.data?.body?.cas_number);
      setSmiles(res?.data?.body?.smiles);
      setMeltingPoint(res?.data?.body?.melting_point || "");
      setHfusion(res?.data?.body?.solid?.general?.heat_of_fusion || "");
      // dispatch(materialAction(res?.data?.body));
      // if (res?.data?.body?.id) getSaveSimulations(res?.data?.body?.id);
    } else {
      dispatch(alertOpenAction("Oops! something went wrong.", "error"));
      setTimeout(() => dispatch(alertCloseAction()));
      return null;
    }
  };

  useEffect(() => {
    if (!!vendorName) getMaterialNames(vendorName);
  }, [vendorName]);

  useEffect(() => {
    if (!!vendorName && !!material) getLotNumbers();
  }, [material]);
  useEffect(() => {
    if (!!lotNumber)
      getMaterial(
        vendorName,
        material,
        lotNumber,
        melting_point,
        heat_of_fusion
      );
  }, [lotNumber,vendorName,material]);
  useEffect(() => {
    setRunEnabled(!!vendorName && !!material && !!lotNumber);
    setCheckStatusEnabled(!!vendorName && !!material && !!lotNumber);
  }, [vendorName, material, lotNumber]);

  // Function to toggle the modal
  const toggleModal = (isOpen: boolean) => {
    setIsModalOpen(isOpen);
  };

  const handleFileUpload = (e: any) => {
    const file = e.target.files[0];
    setUploadedFile(file ? file.name : null);
  };
  const handleRun = async () => {
    setLoading(true);
    try {
      const payload = {
        function_name: "AddMolecule",
        data: {
          compoundName: material,
          cas: casNumber,
          smiles: smiles,
          meltingpoint: melting_point,
          heat_of_fusion: heat_of_fusion,
        },
        deletePrevious: deleteBeforeRun,
      };
      const response = await fetch(`${apiRoutes.COSMO_API_URL}/run_function`, {
        method: "POST",
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        const data = await response.json();
        setRunOutput("Process completed successfully. Check logs for details.");
        toast.success("Run process was successful.");
      } else {
        throw new Error("Run process failed.");
      }
    } catch (error) {
      console.error("Error during run:", error);
      toast.error("Run process encountered an error.");
    } finally {
      setLoading(false);
    }
  };

  const handleCheckStatus = async () => {
    setIsChecking(true);
    try {
      // First, check the current job
      const currentJobResponse = await fetch(
        `${apiRoutes.COSMO_API_URL}/current_job`,
        {
          method: "GET",
          headers: {
            accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );

      if (currentJobResponse.ok) {
        const currentJobData = await currentJobResponse.json();

        // Check if the current job matches the payload data
        if (
          currentJobData.current_job &&
          currentJobData.current_job.function_name === "AddMolecule" &&
          currentJobData.current_job.data.compoundName === material &&
          currentJobData.current_job.data.cas === casNumber &&
          currentJobData.current_job.data.smiles === smiles
        ) {
          setLogStatus("Process is still running...");
          toast.info("Process is still running. Please check again later.");
          return;
        }
      }

      // If the current job does not match or is null, fetch the logs
      const logsResponse = await fetch(`${apiRoutes.COSMO_API_URL}/job_logs`, {
        method: "GET",
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
        },
      });

      if (logsResponse.ok) {
        const logsData: { logs: { stdout: string; stderr: string }[] } =
          await logsResponse.json();

        // Find the relevant log based on compoundName, smiles, and cas
        const relevantLog = logsData.logs.find(
          (log) =>
            log.stdout.includes(material) &&
            log.stdout.includes(smiles) &&
            log.stdout.includes(casNumber)
        );

        if (relevantLog) {
          const regex = /'([^']+)'\s+'([^']+)'\s+--CAS='([^']+)'/;
          const match = relevantLog.stdout.match(regex);

          let materialName = null;
          let smilesMatch = null;
          let casNumberMatch = null;

          if (match) {
            materialName = match[1];
            smilesMatch = match[2];
            casNumberMatch = match[3];
          }

          const successRegex =
            /\[\d{2}\.\d{2}\|\d{2}:\d{2}:\d{2}\] JOB (\w+) SUCCESSFUL/;
          const successMatch = relevantLog.stdout.match(successRegex);
          const jobSuccessMessage = successMatch
            ? `JOB ${successMatch[1]} SUCCESSFUL`
            : null;

          const errorRegex = /\[(\d{2}:\d{2}:\d{2})\]\s+(.*)/g;
          let errors: Set<string> = new Set();

          let errorMatch: RegExpExecArray | null;
          while ((errorMatch = errorRegex.exec(relevantLog.stderr)) !== null) {
            const timeStamp = errorMatch[1];
            const message = errorMatch[2];
            errors.add(`[${timeStamp}] ${message}`);
          }

          setLogStatus(
            `Job logs:\nMaterial: ${materialName}\nSMILES: ${smilesMatch}\nCAS: ${casNumberMatch}\nErrors:\n${
              Array.from(errors).join("\n") || "No errors found"
            }`
          );

          if (
            jobSuccessMessage &&
            relevantLog.stdout.includes(jobSuccessMessage)
          ) {
            setIsDownloadEnabled(true);
            toast.success("Process completed. Download is now enabled.");
            setLogStatus(
              `Job logs:\nMaterial: ${materialName}\nSMILES: ${smilesMatch}\nCAS: ${casNumberMatch}\n${jobSuccessMessage}`
            );
          } else if (errors.size > 0) {
            toast.error("Errors encountered during process execution.");
          } else {
            toast.info("Process is still running. Please check again later.");
          }
        } else {
          toast.info("No matching logs found for this compound.");
        }
      } else {
        throw new Error("Failed to fetch logs.");
      }
    } catch (error) {
      console.error("Error fetching logs:", error);
      toast.error("Could not fetch logs. Please try again later.");
    } finally {
      setIsChecking(false);
    }
  };

  const extractConformerData = (text: string) => {
    const conformerData = [];
    const regex = /ConformerRow\(([\s\S]*?)\)/g;
    let match;

    while ((match = regex.exec(text)) !== null) {
      const data: any = {};
      const parts = match[1].split(/,\s*(?![^"]*")(?!.*\\)/);

      for (const part of parts) {
        let keyValues = part.split("=");
        const key = keyValues[0];
        const value = keyValues[1];

        if (key && value) {
          data[key] = value;
        } else if (key) {
          data[key] = null;
        }
      }
      conformerData.push(data);
    }
    return conformerData;
  };

  const handleCheckStatusSolubility = async () => {
    setIsCheckingSolubility(true);
    try {
      // First, check the current job
      const currentJobResponse = await fetch(
        `${apiRoutes.COSMO_API_URL}/current_job`,
        {
          method: "GET",
          headers: {
            accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );

      if (currentJobResponse.ok) {
        const currentJobData = await currentJobResponse.json();

        // Check if the current job matches the payload data
        if (
          currentJobData.current_job &&
          currentJobData.current_job.function_name === "SolubilityScreen" &&
          currentJobData.current_job.data.name ===
            handleImportMappingData(material) &&
          currentJobData.current_job.data.smiles === smiles &&
          currentJobData.current_job.data.meltingpoint === melting_point &&
          currentJobData.current_job.data.hfusion === heat_of_fusion
        ) {
          // here manage solubility
          setLogStatusSolubility("Process is still running...");
          toast.info("Process is still running. Please check again later.");
          return;
        }
      }

      // If the current job does not match or is null, fetch the logs
      const logsResponse = await fetch(`${apiRoutes.COSMO_API_URL}/job_logs`, {
        method: "GET",
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
        },
      });

      if (logsResponse.ok) {
        const logsData: {
          logs: { task: string; stdout: string; stderr: string }[];
        } = await logsResponse.json();

        // Find the relevant log based on compoundName, smiles, and cas
        const relevantLog = logsData.logs.find(
          (log) =>
            log.task === "SolubilityScreen" &&
            log.stdout.includes(material) &&
            log.stdout.includes(smiles) &&
            log.stdout.includes(melting_point) &&
            log.stdout.includes(heat_of_fusion)
        );

        if (relevantLog) {
          const outPutData = extractConformerData(relevantLog?.stdout);
          const updatedData = outPutData.map((item) => {
            const getMappedName = item?.name
              ? handleImportMappingData(item?.name)
              : "";
            return {
              ...item,
              material_name: getMappedName,
            };
          });

          const newTableData = tableData?.map((tItem) => {
            const foundFromCosmo = updatedData?.find(
              (data) => data?.material_name === tItem?.S1
            );
            return {
              ...tItem,
              solubility: foundFromCosmo ? foundFromCosmo?.Ecosmo : "",
            };
          });

          setTableData(newTableData);
          setLogStatusSolubility("");
        } else {
          toast.info("No matching logs found for this compound.");
        }
      } else {
        throw new Error("Failed to fetch logs.");
      }
    } catch (error) {
      console.error("Error fetching logs:", error);
      toast.error("Could not fetch logs. Please try again later.");
    } finally {
      setIsCheckingSolubility(false);
    }
  };

  const handleSolventData = (data: SolventMixType[]) => {
    toggleModal(false);
    // const updatedData = data?.map((item) => {
    //   return {
    //     ...item,
    //     solubility : (Math.random()).toFixed(4)
    //   }
    // })
    // setTableData(updatedData)

    setTableData(data);
  };

  const handleImportMappingData = async (
    material: string,
    isReverse: boolean = false
  ): Promise<string | null> => {
    try {
      const response = await fetch("/data/SolventList.xlsx");
      const arrayBuffer = await response.arrayBuffer();

      // Read workbook
      const workbook = XLSX.read(arrayBuffer, { type: "array" });
      const sheet = workbook.Sheets[workbook.SheetNames[1]]; // Get second sheet

      // Convert sheet to JSON row-wise
      const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
      if (jsonData.length < 3) return null; // Ensure valid data

      const headers: any = jsonData[1]; // Second row contains column names
      const rowWiseData = jsonData
        .slice(2)
        .map((row: any) =>
          Object.fromEntries(
            headers.map((header: any, index: any) => [header, row[index] || ""])
          )
        );

      const searchKey = isReverse ? "COSMO name" : "Material Name";
      const resultKey = isReverse ? "Material Name" : "COSMO name";

      return (
        rowWiseData.find(
          (item) => item[searchKey]?.toLowerCase() === material.toLowerCase()
        )?.[resultKey] || null
      );
    } catch (error) {
      console.error("Error reading the Excel file:", error);
      return null;
    }
  };

  const handleSolventRunClick = async () => {
    setLoading(true);
    try {
      // const mappedName = await handleImportMappingData("2-Methyl-1-propanol",true);
      const mappedName = await handleImportMappingData(material);

      const payload = {
        function_name: "SolubilityScreen",
        data: {
          name: mappedName,
          smiles: smiles,
          meltingpoint: melting_point,
          hfusion: heat_of_fusion,
        },
      };

      const response = await fetch(`${apiRoutes.COSMO_API_URL}/run_function`, {
        method: "POST",
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        const data = await response.json();
        setRunOutput("Process completed successfully. Check logs for details.");
        toast.success("Run process was successful.");
      } else {
        throw new Error("Run process failed.");
      }
    } catch (error) {
      console.error("Error during run:", error);
      toast.error("Run process encountered an error.");
    } finally {
      setLoading(false);
    }
  };

  const manageSort = (newSortBy: string) => {
    let newSortOrder: SortOrder = SortOrder.DESC;
    if (newSortBy == sortBy) {
      newSortOrder =
        sortOrder == SortOrder.ASC ? SortOrder.DESC : SortOrder.ASC;
    }
    setSortBy(newSortBy);
    setSortOrder(newSortOrder);
    setTableData(sortByField(tableData, newSortBy, newSortOrder));
  };

  const exportToExcel = (uData: any, filename = "data.xlsx") => {
    const data = uData?.map((item: any) => {
      const { imported, ...rest } = item;
      return {
        ...rest,
      };
    });
    // Convert JSON to worksheet
    const worksheet = XLSX.utils.json_to_sheet(data);

    // Create a new workbook and append the worksheet
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    // Write the file and trigger download
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const dataBlob = new Blob([excelBuffer], {
      type: "application/octet-stream",
    });
    saveAs(dataBlob, filename);
  };

  const handleSolubilityDownload = async () => {
    if (tableData.length <= 0) {
      toast.warning("Please add mixture first");
      return;
    }
    if (selectedOption === "") {
      toast.warning("Please select an option first");
      return;
    } else if (
      selectedOption === "Solubilities" ||
      selectedOption === "Anti-Solvents" ||
      selectedOption === "Solvents"
    ) {
      let entries;

      if (selectedOption === "Solvents") {
        setSortBy("solubility");
        setSortOrder(SortOrder.DESC);
        const updatedData = sortByField(
          tableData,
          "solubility",
          SortOrder.DESC
        );
        entries = updatedData.slice(0, 15);
      } else if (selectedOption === "Anti-Solvents") {
        setSortBy("solubility");
        setSortOrder(SortOrder.ASC);
        const updatedData = sortByField(tableData, "solubility", SortOrder.ASC);
        entries = updatedData.slice(0, 15);
      } else {
        setSortBy("solubility");
        setSortOrder(SortOrder.ASC);
        const updatedData = sortByField(tableData, "solubility", SortOrder.ASC);
        entries = updatedData;
      }

      // i want to download entries as an xlsx file
      exportToExcel(entries, "cosmo_solubility_data.xlsx");
    } else {
      toast.warning(
        "Invalid option selected, please choose a different option"
      );
      return;
    }
  };

  return (
    <div className="">
      {loading && <CustomLoader />}
      <SolventListModal
        isModalOpen={isModalOpen}
        toggleModal={toggleModal}
        handleSolventData={handleSolventData}
      />
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 mb-6 p-4 rounded-lg shadow-sm">
        <div className="w-full">
          <div className="form-group">
            <label className="ip-wrap required" htmlFor="vendor">
              <span className="form-label">Vendor</span>
              <div className="input-wrap select-outer">
                <select
                  className="theme-ip"
                  onChange={(e) => {
                    setVendorName(e.target.value);
                  }}
                  value={vendorName}
                >
                  <option value="" disabled={true}>
                    Select
                  </option>
                  {vendors?.map((vendor: VendorProps, index: number) => (
                    <option key={index} value={vendor?.value}>
                      {vendor.label}
                    </option>
                  ))}
                </select>
              </div>
            </label>
          </div>
        </div>
        <div className="w-full">
          <div className="form-group">
            <label className="ip-wrap required" htmlFor="material_name">
              <span className="form-label">Material Name</span>
              <div className="input-wrap select-outer">
                <select
                  className="theme-ip"
                  onChange={(e) => {
                    setMaterial(e.target.value);
                  }}
                  value={material}
                >
                  <option value="" disabled={true}>
                    Select
                  </option>
                  {materialNameOptions?.map((name: any, index: number) => (
                    <option key={index} value={name.material_name}>
                      {name?.material_name}
                    </option>
                  ))}
                </select>
              </div>
            </label>
          </div>
        </div>
        <div className="w-full">
          <div className="form-group ">
            <label
              className="ip-wrap required"
              htmlFor="lot_number"
              title={"Lot Number"}
            >
              <span className="form-label">Lot Number</span>
              <div className="input-wrap select-outer">
                <select
                  className="theme-ip"
                  onChange={(e) => {
                    setLotNumber(e.target.value);
                  }}
                  value={lotNumber}
                >
                  <option value="" disabled={true}>
                    Select
                  </option>
                  {lotNumberOptions?.map((name: any, index: number) => (
                    <option key={index} value={name.lot_number}>
                      {name?.lot_number}
                    </option>
                  ))}
                </select>
              </div>
            </label>
          </div>
        </div>
      </div>

      {/* molecule start  */}
      <div className="flex flex-col sm:flex-row gap-4 p-4 mb-6 rounded-lg">
        <div className="w-full sm:w-5/12 flex items-center justify-center">
          <span className="form-label">Create a COSMO molecule file</span>
        </div>
        <div className="w-full sm:w-7/12 flex flex-col gap-4">
          {/* Optional Parameters */}
          <div className="flex flex-col gap-4 w-full p-3 rounded-md">
            <label className="form-label text-sm text-gray-700 font-semibold">
              Additional Model Inputs:
            </label>
            <div className="flex flex-col gap-4">
              <label className="form-label text-sm text-gray-700">
                Melting Point
              </label>
              <div className="flex items-center gap-2">
                <input
                  type="text"
                  className="w-full sm:w-auto p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-theme"
                  value={melting_point}
                  onChange={(e) => setMeltingPoint(e.target.value)}
                  disabled={!mpEnabled}
                />
                <input
                  type="checkbox"
                  className="h-4 w-4 text-theme"
                  checked={mpEnabled}
                  onChange={(e) => setMpEnabled(e.target.checked)}
                />
                <label className="form-label text-sm text-gray-700">
                  override?
                </label>
              </div>
              <label className="form-label text-sm text-gray-700">
                Hfusion
              </label>
              <div className="flex items-center gap-2">
                <input
                  type="text"
                  className="w-full sm:w-auto p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-theme"
                  value={heat_of_fusion}
                  onChange={(e) => setHfusion(e.target.value)}
                  disabled={!hfEnabled}
                />
                <input
                  type="checkbox"
                  className="h-4 w-4 text-theme"
                  checked={hfEnabled}
                  onChange={(e) => setHfEnabled(e.target.checked)}
                />
                <label className="form-label text-sm text-gray-700">
                  override?
                </label>
              </div>
            </div>
          </div>
          <div className="divider"></div>
          <div className="flex items-center gap-2">
            <input
              type="checkbox"
              className="h-4 w-4 text-theme"
              checked={deleteBeforeRun}
              onChange={(e) => setDeleteBeforeRun(e.target.checked)}
            />
            <label className="form-label text-sm text-gray-700">
              Delete & Run
            </label>
          </div>
          {/* Run and Check Status */}
          <div className="flex flex-wrap gap-4 items-center mt-4">
            <button
              type="button"
              disabled={!runEnabled || loading}
              className={`min-w-[120px] max-w-[400px] w-40 sm:w-auto bg-theme text-white py-2 px-4 rounded-lg text-lg sm:px-3 md:px-5 lg:px-6 sm:py-1 md:py-2 lg:py-3 ${
                !runEnabled && "opacity-50 hover:bg-gray-400"
              }`}
              onClick={handleRun}
            >
              {loading ? "Running..." : "Run"}
            </button>
            <button
              type="button"
              className={`min-w-[120px] max-w-[400px] w-40 sm:w-auto ${
                isChecking ? "bg-gray-400" : "bg-theme"
              } text-white py-2 px-4 rounded-lg text-lg sm:px-3 md:px-5 lg:px-6 sm:py-1 md:py-2 lg:py-3`}
              onClick={handleCheckStatus}
              disabled={!checkStatusEnabled || loading || isChecking}
            >
              {isChecking ? "Checking..." : "Check Status"}
            </button>
          </div>

          {/* Scrollable Log Status */}
          <div className="mt-6">
            <textarea
              className="w-full h-32 p-2 border border-gray-300 rounded-md bg-white overflow-y-auto resize scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-gray-200"
              value={logStatus}
              readOnly
            />
          </div>
        </div>
      </div>
      {/* molecule end  */}

      <div className="divider"></div>

      <div className="flex flex-col sm:flex-row gap-4 p-4 mb-6 rounded-lg">
        <div className="w-full sm:w-5/12 flex items-center justify-center">
          <span className="form-label">Solvent Screening</span>
        </div>
        <div className="w-full sm:w-7/12 flex flex-col items-center sm:items-start gap-4">
          {/* Run Button */}
          <button
            type="button"
            className="min-w-[120px] max-w-[400px] w-40 sm:w-auto bg-theme text-white py-2 px-4 rounded-lg text-lg sm:px-3 md:px-5 lg:px-6 sm:py-1 md:py-2 lg:py-3"
            onClick={() => setIsModalOpen(true)}
          >
            Manage Solvent System
          </button>
        </div>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-1 gap-4 p-4 mb-6p-4 rounded-lg shadow-sm">
        <div className="relative">
          <h3 className="text-center">Solvents</h3>
          <div className="relative cosmo-solubility-table overflow-x-auto overflow-y-auto h-64">
            <Table
              data={tableData}
              manageSort={manageSort}
              sortBy={sortBy}
              sortOrder={sortOrder}
            />
          </div>
        </div>
      </div>
      <div className="flex justify-center mt-6 gap-4 items-center rounded-lg">
        <button
          type="button"
          className={`min-w-[120px] max-w-[320px] w-20 sm:w-auto bg-theme text-white py-2 px-4 rounded-lg text-lg sm:px-3 md:px-5 lg:px-6 sm:py-1 md:py-2 lg:py-3 ${
            tableData.length === 0 && "opacity-50"
          }`}
          onClick={handleSolventRunClick}
          disabled={tableData.length === 0}
        >
          Run
        </button>
        <button
          type="button"
          className={`min-w-[120px] max-w-[320px] w-20 sm:w-auto bg-theme ${
            !material || !lotNumber ? "opacity-50" : ""
          } text-white py-2 px-4 rounded-lg text-lg sm:px-3 md:px-5 lg:px-6 sm:py-1 md:py-2 lg:py-3`}
          onClick={handleCheckStatusSolubility}
          disabled={!material || !lotNumber}
        >
          {isChecking ? "Checking..." : "Check Status"}
        </button>
      </div>
      {logStatusSolubility !== "" && (
        <div className="py-2 px-4">{logStatusSolubility}</div>
      )}
      <div className="divider"></div>
      <div className="grid grid-cols-1 gap-4 p-4 mb-6p-4 rounded-lg shadow-sm">
        <div className="w-full flex flex-col items-center justify-center">
          <h3>Solubilities</h3>
          <div className="relative overflow-x-auto">
            <ContourGraph plot={undefined} />
          </div>
        </div>
      </div>
      <div className="flex flex-col sm:flex-row items-center gap-4 p-4 mb-6 rounded-lg shadow-sm justify-center">
        <div className="w-full sm:w-1/6">
          <span className="form-label">Download Results</span>
        </div>
        <div className="w-full sm:w-1/5">
          <div className="input-wrap select-outer">
            <select
              className="theme-ip w-full"
              value={selectedOption}
              onChange={(e) => {
                manageSort("solubility");
                setSelectedOption(e.target.value);
              }}
            >
              <option value="">Select</option>
              <option value="Solvents">Solvents</option>
              <option value="Anti-Solvents">Anti-Solvents</option>
              <option value="Solubilities">Solubilities</option>
            </select>
          </div>
        </div>
        <div className="w-full sm:w-1/6 mt-4 sm:mt-0">
          <button
            type="button"
            className="bg-theme text-white py-2 px-4 rounded-lg text-lg w-full"
            onClick={handleSolubilityDownload}
          >
            Download
          </button>
        </div>
      </div>
    </div>
  );
};

export default CosmoRevised;
